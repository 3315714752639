import React, { useContext } from 'react'
import cx from 'classnames'
import { TailwindElement } from './TailwindElement'
import { useNavigationRailState } from './NavigationRailContext'

export type NavLinkDensity = 'default' | 'condensed'

const linkStyles = ({
  isActive,
  isHover,
  density
}: {
  isActive?: boolean
  isHover?: boolean
  density?: NavLinkDensity
}) => ({
  base: [
    density === 'condensed' ? 'py-1.5 h-8' : 'py-2 h-10',
    'type-default md:type-subhead',
    'flex items-center flex-grow w-full outline-none',
    'focus-visible:shadow-focus',
    'rounded-button no-underline',
    isHover && !isActive && 'bg-darken-4',
    isHover && isActive && 'bg-brand-10',
    !isHover && isActive && 'bg-brand-0',
    isActive
      ? 'font-semibold text-brand-75 hover:bg-brand-10 active:bg-brand-10 focus-visible:text-brand-75'
      : 'text-default hover:bg-darken-4'
  ]
})

const NavigationLinkContext = React.createContext({
  isActive: false,
  isHover: false
})

export function NavigationItem({
  isActive = false,
  isHover = false,
  className,
  baseClasses = 'list-none flex items-center my-1',
  density,
  ...props
}: {
  isActive?: boolean
  isHover?: boolean
  density?: NavLinkDensity
} & TailwindElement<HTMLLIElement>) {
  return (
    <NavigationLinkContext.Provider value={{ isActive, isHover }}>
      <li
        className={cx(
          { 'my-0.5': density === 'condensed' },
          className,
          baseClasses
        )}
        {...props}
      />
    </NavigationLinkContext.Provider>
  )
}

export function NavigationLink({
  as: Component = 'a',
  testId,
  density,
  ...props
}: TailwindElement<HTMLAnchorElement> & {
  as?: React.ElementType | keyof JSX.IntrinsicElements
  testId?: string
  density?: NavLinkDensity
}) {
  const { isActive, isHover } = useContext(NavigationLinkContext)
  const styles = linkStyles({ isActive, isHover, density })
  return (
    <Component
      className={cx(styles.base)}
      style={{ maxHeight: '3rem' }}
      data-testid={testId}
      {...props}
    />
  )
}

export function NavigationIcon({ icon: Icon, ...props }: { icon: any }) {
  const { isActive } = useContext(NavigationLinkContext)
  return (
    <Icon
      className={cx({
        'ml-3 mr-2': true,
        'text-secondary': !isActive
      })}
      accessibility='decorative'
      {...props}
    />
  )
}

export function NavigationItemText({
  baseClasses = 'py-1 whitespace-nowrap overflow-hidden ellipsis block transition-all',
  ...props
}: {
  isExpandable?: boolean
  isExpanded?: boolean
} & TailwindElement<HTMLSpanElement>) {
  const { canExpand, isExpanded } = useNavigationRailState()
  return (
    <span
      className={cx(baseClasses, {
        'sr-only': !isExpanded && canExpand,
        'opacity-0 duration-200 ease-out-quart': canExpand,
        'group-hover:delay-75 group-hover:duration-75 opacity-100 group-hover:opacity-100 group-hover:visible group-hover:transition-all group-hover:ease-out-quart':
          isExpanded && canExpand
      })}
      {...props}
    />
  )
}
