import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CommunityIcon = React.forwardRef(function CommunityIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M6.44 15.72l-.419 1.592H4.086l-.899-3.409V13.9h-.904v-1.355a2.274 2.274 0 012.274-2.273h.578c.381-.001.757.095 1.091.278m11.285 5.17l.42 1.592h1.935l.899-3.409V13.9h.903v-1.355a2.273 2.273 0 00-2.273-2.273h-.578c-.382-.001-.757.095-1.092.278M2.388 18.861c-.247.135-.388.276-.388.423 0 .853 4.477 1.545 10 1.545s10-.692 10-1.545c0-.147-.14-.288-.388-.423M14.31 5.535a2.364 2.364 0 11-4.729 0 2.364 2.364 0 014.73 0zm6.458 1.778a1.88 1.88 0 11-3.76 0 1.88 1.88 0 013.76 0zm-5.434 4.766a3.106 3.106 0 00-3.286-2.886h-.023a3.106 3.106 0 00-3.286 2.886v1.736h1.38l.833 4.696h1.933l.833-4.696h1.616v-1.736zM6.941 7.4a1.856 1.856 0 11-3.712 0 1.856 1.856 0 013.712 0z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
