import * as React from 'react'
import cx from 'classnames'
import { TailwindElement } from '../Navigation/TailwindElement'

export function DesktopNavigationLayout({
  className,
  // elevation: z-20 for global navigation according to our guidelines
  baseClasses = 'absolute top-0 z-20 w-full md:pt-16 md:w-auto print:hidden',
  ...props
}: TailwindElement<HTMLDivElement>) {
  return <div className={cx(baseClasses, className)} {...props} />
}

export function HeaderContainer({
  baseClasses = 'top-0 left-0 w-full md:fixed',
  className,
  ...props
}: TailwindElement<HTMLDivElement>) {
  return <div className={cx(baseClasses, className)} {...props} />
}
