import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ComputerCheckIcon = React.forwardRef(function ComputerCheckIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M22.8 14a.75.75 0 10-1.5 0h1.5zM9.15 4.75a.75.75 0 100-1.5v1.5zm5.873 2.167a.75.75 0 00-1.147.966l1.148-.966zM16.05 9.3l-.574.483a.75.75 0 001.18-.04L16.05 9.3zm3.605-3.657a.75.75 0 10-1.21-.886l1.21.886zM2.05 14.95a.75.75 0 100 1.5v-1.5zm20 1.5a.75.75 0 000-1.5v1.5zm-16 4.8a.75.75 0 100 1.5v-1.5zm12 1.5a.75.75 0 000-1.5v1.5zm-2.2-.75v.75a.75.75 0 00.679-1.068l-.68.318zm-7.7 0l-.68-.318a.75.75 0 00.68 1.068V22zm1.5-3.2v-.75a.75.75 0 00-.68.432l.68.318zm4.7 0l.679-.318a.75.75 0 00-.68-.432v.75zM21.3 14v2.9h1.5V14h-1.5zm0 2.9c0 .561-.54 1.15-1.25 1.15v1.5c1.489 0 2.75-1.211 2.75-2.65h-1.5zm-1.25 1.15h-16v1.5h16v-1.5zm-16 0c-.737 0-1.25-.514-1.25-1.15H1.3c0 1.564 1.287 2.65 2.75 2.65v-1.5zM2.8 16.9v-11H1.3v11h1.5zm0-11c0-.561.539-1.15 1.25-1.15v-1.5C2.56 3.25 1.3 4.461 1.3 5.9h1.5zm1.25-1.15h5.1v-1.5h-5.1v1.5zM21.3 7.2a4.55 4.55 0 01-4.55 4.55v1.5A6.05 6.05 0 0022.8 7.2h-1.5zm-4.55 4.55A4.55 4.55 0 0112.2 7.2h-1.5a6.05 6.05 0 006.05 6.05v-1.5zM12.2 7.2a4.55 4.55 0 014.55-4.55v-1.5A6.05 6.05 0 0010.7 7.2h1.5zm4.55-4.55A4.55 4.55 0 0121.3 7.2h1.5a6.05 6.05 0 00-6.05-6.05v1.5zm-2.874 5.233l1.6 1.9 1.147-.966-1.6-1.9-1.147.966zm2.78 1.86l3-4.1-1.211-.886-3 4.1 1.21.886zM2.05 16.45h20v-1.5h-20v1.5zm4 6.3h12v-1.5h-12v1.5zm9.8-1.5h-7.7v1.5h7.7v-1.5zm-7.021 1.068l1.5-3.2-1.358-.636-1.5 3.2 1.358.636zm.82-2.768h4.7v-1.5h-4.7v1.5zm4.022-.432l1.5 3.2 1.358-.636-1.5-3.2-1.358.636z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
