import * as React from 'react'
import { Variant } from './MenuDropdown'

interface MenuDropdownContextProps {
  variant: Variant
  preventCloseOnClick: boolean
  closeDropdown: Function
  getItemProps?: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
  isOpen: boolean
}

export const MenuDropdownContext =
  React.createContext<MenuDropdownContextProps>({
    getItemProps: () => ({}),
    isOpen: false,
    variant: 'white',
    preventCloseOnClick: false,
    closeDropdown: () => null
  })

export const useMenuDropdownContext = () => {
  return React.useContext(MenuDropdownContext)
}
