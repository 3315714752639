import { JSX, useCallback, useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'
import { LinkItem } from '@toasttab/buffet-pui-global-navigation'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { ListItem } from '@toasttab/buffet-pui-dropdowns'

import { NavigationLinkItem } from '.'
import { convertToDashCase } from './utils'
import {
  getSecondaryForEnvironment,
  useEnvironmentContext
} from '@local/environment-context'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { BaseItem } from '../../src/app/navigationLinks/baseItems/type'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'

export interface MenuItemProps {
  item: NavigationLinkItem
  onClick?: (item: NavigationLinkItem) => void
}

export function MenuItem({
  item,
  onClick = () => {}
}: MenuItemProps): JSX.Element {
  const linkToMatch = !!item?.to ? `${item.to}/*` : item.href
  const match = useMatch(linkToMatch ?? '')

  const linkRef = useRef<HTMLDivElement>(null)

  const { disableSelection, environmentSelection } = useEnvironmentContext()

  const regexEditPagePatterns = new RegExp(/(\/edit)|(\/create)|(\/update)/)
  const regexPaymentMethodPagePatterns = new RegExp(/(\/payment-methods)/)
  useEffect(() => {
    if (match) {
      disableSelection(
        regexEditPagePatterns.test(match.pathname) ||
          regexPaymentMethodPagePatterns.test(match.pathname)
      )
    }
  }, [match])

  const shouldDisableNavigationLinkInEnv = useCallback(
    (item: BaseItem) => {
      if (item.id !== 'payment-methods') return false

      const currentToastEnv = getCurrentEnvironment() as string
      return (
        environmentSelection === getSecondaryForEnvironment(currentToastEnv)
      )
    },
    [environmentSelection]
  )

  if (shouldDisableNavigationLinkInEnv(item)) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <ListItem
            icon={<WarningOutlineIcon />}
            subLabel={item.label}
            disabled={true}
          />
        </Tooltip.Trigger>
        <Tooltip.Content>
          The page is not available in the current environment.
        </Tooltip.Content>
      </Tooltip>
    )
  }

  return (
    <div
      ref={linkRef}
      onClick={() => onClick(item)}
      data-testid={`${item.id}-link`}
      data-toast-track-id={`tpc-depot-nav-${convertToDashCase(item.label)}`}
    >
      <LinkItem
        isActive={!!match}
        icon={item.icon}
        to={item.to}
        href={item.href}
        openNewTab={item.newTab ?? false}
      >
        {item.label}
      </LinkItem>
    </div>
  )
}
