import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ChatHelpIcon = React.forwardRef(function ChatHelpIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              clipRule='evenodd'
              d='M12.3 2.8c3.6 0 6.8 2.3 8 5.6 1.2 3.4.1 7.1-2.7 9.4a8.48 8.48 0 01-9.5.8l-4.6 2.2c-.1 0-.2.1-.2.1-.1 0-.3-.1-.4-.2-.2-.2-.2-.4-.1-.6L5 15.4c-1.5-2.5-1.5-5.7 0-8.3 1.4-2.7 4.2-4.3 7.3-4.3z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12.4 15.7a.4.4 0 11-.8 0 .4.4 0 01.8 0z'
              fill='currentColor'
              stroke='currentColor'
            />
            <path
              d='M9.4 8.8s.5-2.4 2.6-2.4c.7 0 1.5.2 2 .8.5.5.8 1.3.5 2.1-.2.6-.7 1-1.1 1.4-.6.5-1.4 1.3-1.4 2.2'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
