import { getCurrentEnvironment } from '@toasttab/current-environment'
import {
  ComputerCheckIcon,
  ComputerSettingsIcon
} from '@toasttab/buffet-pui-icons'
import { Select } from '@toasttab/buffet-pui-select'
import {
  useEnvironmentContext,
  getMainForEnvironment,
  getSecondaryForEnvironment
} from '@local/environment-context'

const ENVIRONMENT_SUBTITLES: { [key: string]: string } = {
  dev: 'DEVELOPMENT',
  preprod: 'PREPRODUCTION',
  sandbox: 'SANDBOX',
  prod: 'PRODUCTION'
}

export const EnvironmentHeader = () => {
  //Toast Environment
  const env = getCurrentEnvironment() as string

  //Active environment
  const {
    isSwitcherEnabled,
    isSelectionDisabled,
    environmentSelection,
    switchEnvironment
  } = useEnvironmentContext()

  return (
    <h1 className='type-large font-semibold ml-0 text-gray-125 flex items-center'>
      Developer Portal
      {isSwitcherEnabled ? (
        <Switcher
          isSelectionDisabled={isSelectionDisabled}
          environmentSelection={environmentSelection}
          env={env}
          switchEnvironment={switchEnvironment}
        />
      ) : (
        <DefaultEnvironmentLabel env={env} />
      )}
    </h1>
  )
}

function Switcher({
  isSelectionDisabled,
  environmentSelection,
  env,
  switchEnvironment
}: {
  isSelectionDisabled: boolean
  environmentSelection: string | undefined
  env: string
  switchEnvironment: (selection: string) => void
}) {
  return (
    <span className='ml-10 w-auto'>
      <Select
        disabled={isSelectionDisabled}
        testId='select-environment'
        label=''
        value={environmentSelection}
        options={[
          {
            label: getMainForEnvironment(env),
            value: getMainForEnvironment(env),
            iconLeft: <ComputerCheckIcon accessibility='decorative' />
          },
          {
            label: getSecondaryForEnvironment(env),
            value: getSecondaryForEnvironment(env),
            iconLeft: <ComputerSettingsIcon accessibility='decorative' />
          }
        ]}
        onChange={(selection: string) => switchEnvironment(selection)}
      />
    </span>
  )
}

function DefaultEnvironmentLabel({ env }: { env: string }) {
  return (
    <span
      className='type-caption font-normal ml-10'
      style={{ letterSpacing: '1.2px' }}
      data-testid='toast-environment'
    >
      {ENVIRONMENT_SUBTITLES[env]}
    </span>
  )
}
