export const styles = {
  light: {
    icon: 'text-white',
    activeIcon: '',
    divider: 'bg-lighten-60'
  },
  dark: {
    icon: 'text-secondary',
    activeIcon: 'bg-brand-0 text-brand-100',
    divider: 'bg-gray-100'
  }
}

export type variantType = keyof typeof styles
