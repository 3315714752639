import React, { MouseEventHandler } from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { styles, variantType } from './styles'
import { IconProps } from '@toasttab/buffet-pui-icons'

interface HeaderToggleProps {
  isActive?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  icon: React.FC<React.PropsWithChildren<IconProps>> | any
  variant?: variantType
  label: string
}

export function HeaderToggle({
  isActive = false,
  onClick = () => {},
  variant = 'light',
  label,
  icon: Icon,
  ...props
}: HeaderToggleProps) {
  return (
    <IconButton
      size='lg'
      aria-label={label}
      onClick={onClick}
      icon={
        React.isValidElement(Icon) ? (
          Icon
        ) : (
          <Icon
            className={
              isActive
                ? styles[variant as variantType].activeIcon
                : styles[variant as variantType].icon
            }
            accessibility='decorative'
          />
        )
      }
      {...props}
    />
  )
}
