import React from 'react'
import { IconButton, IconButtonProps } from '@toasttab/buffet-pui-buttons'
import { MenuIcon } from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

export function MobileHeader(props: React.ComponentProps<'header'>) {
  return <header className='print:hidden' {...props} />
}

export function MobileMenuToggle({ onClick }: Omit<IconButtonProps, 'icon'>) {
  loadStrings()
  return (
    <IconButton
      size='lg'
      aria-label={t('navigation-toggle')} //'Navigation toggle'
      icon={<MenuIcon className='text-white' accessibility='decorative' />}
      onClick={onClick}
    />
  )
}

export function BrandedMobileBackground(props: React.ComponentProps<'div'>) {
  return (
    <div className='h-12 bg-brand-50'>
      <div className='flex items-center' {...props} />
    </div>
  )
}
