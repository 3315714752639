import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CallSupportIcon = React.forwardRef(function CallSupportIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M18.48 15.96v.36s.36 3.6-2.16 3.6h-1.08M5.52 9.84V8.4C5.52 5.909 7.95 3 12 3c4.05 0 6.48 2.909 6.48 5.4v1.44m-4.59 8.46h-3.78a1.35 1.35 0 100 2.7h3.78a1.35 1.35 0 100-2.7zm-7.92-2.34C4.627 15.96 3 15.852 3 13.8V12c0-1.62 1.627-2.16 2.97-2.16v6.12zm11.79-6.12c1.343 0 3.24.108 3.24 2.16v1.8c0 1.62-1.897 2.16-3.24 2.16V9.84z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
