import React from 'react'
import cx from 'classnames'

export function ToastDesktopLogo({ isOpen = false }) {
  return (
    <a href='/'>
      <div
        className={cx(
          'bg-brand-50 text-white h-16 flex items-center justify-center duration-300 ease-out-quart',
          'overflow-hidden',
          { 'px-10': isOpen, 'w-16': !isOpen }
        )}
        style={isOpen ? { width: '13rem', flex: 0 } : { flex: 0 }}
      >
        <ExpandableLogo isOpen={isOpen} />
      </div>
    </a>
  )
}

function ExpandableLogo({ isOpen = false }) {
  return (
    <svg className='overflow-hidden' fill='white'>
      <g
        style={!isOpen ? { transform: `translateX(12px)` } : {}}
        className='transition duration-75 ease-out-quart'
      >
        <path
          d='M175.985 109.955c-.806 1.844-3.086 1.638-4.674 1.5-2.38-.204-4.718-.788-7.11-.86-2.116-.064-4.21.342-6.27.804-1.546.342-3.696 1.246-5.092.04-1.544-1.336-1.82-3.612-1.842-5.526-.018-1.656.236-3.522.494-5.16.168-1.056.522-2.07.908-3.066.556-1.43 1.372-2.288 1.412-2.244-.414-.436-.912-.668-1.192-1.268-1.056-2.246 1.58-4.144 3.024-4.8 5.046-2.296 12.11-2.068 16.89.88 1.488.916 4.378 4.08 1.676 5.652 1.666.798 2.088 3.206 2.272 4.92a30.385 30.385 0 01-.246 8.248c-.056.336-.14.626-.25.88zm3.704-3.772c.008-2.098-.05-4.218-.274-6.298-.15-1.414-.624-3.222-2.126-3.59.172.044.46.048.588-.02 1.746-.93 1.614-3.216.994-4.872-.812-2.168-2.74-3.702-4.652-4.724-6.858-3.666-16.04-3.36-22.692.816-1.732 1.088-3.724 3.21-2.85 5.562.222.594.614 1.108 1.038 1.568.196.208.66.694.66.696-.896 1.232-1.544 2.336-1.964 3.832-1.263 4.488-1.053 7.376-.706 10.982.214 2.23.94 5.56 3.474 5.924.002.002.006.002.01.002 2.062.29 4.304-.4 6.36-.646 2.274-.272 4.564-.698 6.856-.638 3.236.084 6.45.868 9.692.898 1.71.016 4.56.184 5.066-2.11.486-2.178.496-4.45.52-6.676l.006-.706'
          transform='translate(-144 -24)'
        />
        <g opacity={isOpen ? 1 : 0} className='transition duration-300'>
          <path
            d='M191.668 114c-3.5 0-5.388-1.81-5.388-5.191v-9.91h-1.219c-1.18 0-2.162-.983-2.162-2.241 0-1.14.983-2.124 2.162-2.124h1.22v-2.95c0-1.376 1.1-2.477 2.556-2.477a2.467 2.467 0 012.477 2.478v2.95h1.966c1.14 0 2.124.982 2.124 2.201 0 1.18-.983 2.163-2.124 2.163h-1.966v8.573c0 1.494.944 2.084 1.73 2.084h.433c1.416 0 2.202.944 2.202 2.163 0 1.258-.747 2.28-4.011 2.28'
            transform='translate(-144 -24)'
          />
          <path
            d='M207.399 98.506c-3.107 0-4.837 2.556-4.837 5.505 0 2.989 1.73 5.545 4.837 5.545 3.106 0 4.876-2.556 4.876-5.545 0-2.949-1.77-5.505-4.876-5.505zm0 15.494c-6.253 0-10.028-4.562-10.028-9.989 0-5.387 3.775-9.949 10.028-9.949 6.292 0 10.067 4.562 10.067 9.95 0 5.426-3.775 9.988-10.067 9.988'
            transform='translate(-144 -24)'
          />
          <path
            d='M232.568 106.607c-.826-1.101-2.399-1.652-4.012-1.652-1.966 0-3.578 1.023-3.578 2.87 0 1.77 1.612 2.793 3.578 2.793 1.613 0 3.186-.55 4.012-1.652zm2.556 7.157h-.394c-.393 0-2.162-.315-2.162-2.202-1.298 1.533-3.54 2.438-6.017 2.438-3.028 0-6.607-2.045-6.607-6.292 0-4.444 3.579-6.135 6.607-6.135 2.517 0 4.758.826 6.017 2.32v-2.556c0-1.926-1.652-3.185-4.17-3.185-1.493 0-2.948.354-4.246 1.219-.275.157-.59.275-.904.275-.905 0-1.888-.747-1.888-1.926 0-.355.118-.709.275-.984 1.18-1.887 5.427-2.674 7.63-2.674 4.325 0 8.297 1.73 8.297 7.197v10.028c0 1.376-1.101 2.477-2.438 2.477'
            transform='translate(-144 -24)'
          />
          <path
            d='M249.36 114c-2.477 0-6.449-.826-7.629-2.517a1.724 1.724 0 01-.353-1.062c0-1.18 1.022-2.084 2.084-2.084.353 0 .707.079 1.022.276 1.495.865 3.46 1.533 5.112 1.533 2.163 0 3.185-.865 3.185-2.045 0-3.146-11.286-.59-11.286-8.062 0-3.185 2.792-5.977 7.787-5.977 2.477 0 5.859.787 6.92 2.32a1.719 1.719 0 01.315.983c0 1.062-.943 1.888-1.966 1.888-.315 0-.669-.078-.943-.236a8.71 8.71 0 00-4.287-1.14c-1.81 0-2.989.826-2.989 1.888 0 2.83 11.247.471 11.247 8.14 0 3.46-2.95 6.095-8.219 6.095'
            transform='translate(-144 -24)'
          />
          <path
            d='M267.647 114c-3.5 0-5.387-1.81-5.387-5.191v-9.91h-1.22c-1.179 0-2.162-.983-2.162-2.241 0-1.14.983-2.124 2.162-2.124h1.22v-2.95c0-1.376 1.101-2.477 2.556-2.477a2.467 2.467 0 012.477 2.478v2.95h1.967c1.14 0 2.123.982 2.123 2.201 0 1.18-.983 2.163-2.123 2.163h-1.967v8.573c0 1.494.944 2.084 1.73 2.084h.434c1.415 0 2.202.944 2.202 2.163 0 1.258-.748 2.28-4.012 2.28'
            transform='translate(-144 -24)'
          />
        </g>
      </g>
    </svg>
  )
}
