import * as React from 'react'
import { HeaderControl, TailwindElement } from '..'

export const HeaderButton = React.forwardRef<
  HTMLButtonElement,
  TailwindElement<HTMLButtonElement>
>(function HeaderButton({ children, ...props }, ref) {
  return (
    <HeaderControl type='button' ref={ref} {...props}>
      {children}
    </HeaderControl>
  )
})
