import * as React from 'react'
import cx from 'classnames'
import { TailwindElement } from '../Navigation/TailwindElement'
import { ToastDesktopLogo } from './DesktopLogo'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuChevronLeftIcon, MenuIcon } from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

export function DesktopHeader({ isOpen, ...props }: any) {
  return (
    <DesktopContainer>
      <ToastDesktopLogo isOpen={isOpen} />
      <HeaderContents {...props} />
    </DesktopContainer>
  )
}

export function NavigationRailToggleButton({ isOpen = false, ...props }) {
  loadStrings()
  return (
    <IconButton
      aria-label={t('toggle-left-rail-display')}
      className='cursor-pointer'
      icon={
        isOpen ? (
          <MenuChevronLeftIcon
            className='text-secondary'
            aria-label={t('close')}
          />
        ) : (
          <MenuIcon className='text-secondary' aria-label={t('open')} />
        )
      }
      {...props}
    />
  )
}

export function DesktopContainer({
  // elevation: z-20 for global navigation according to our guidelines
  baseClasses = 'bg-white flex items-center sticky w-screen top-0 z-20 print:hidden',
  className,
  ...props
}: TailwindElement<HTMLDivElement>) {
  return <div className={cx(baseClasses, className)} {...props} />
}

export function HeaderContents({
  baseClasses = 'px-6 flex-1 flex items-center space-x-3 lg:space-x-5 h-16 border-b',
  className,
  ...props
}: TailwindElement<HTMLDivElement>) {
  return <div className={cx(baseClasses, className)} {...props} />
}

export function HeaderDivider({
  defaultStyle = {
    width: '1px',
    height: '44px',
    background: 'rgba(0, 0, 0, 0.12)'
  },
  style = {},
  ...props
}: {
  style?: any
  defaultStyle?: any
}) {
  return (
    <div
      style={{
        ...defaultStyle,
        ...style
      }}
      {...props}
    />
  )
}
