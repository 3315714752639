import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const SignIcon = React.forwardRef(function SignIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M17.75 10.7h1.2c1.1 0 2 .9 2 2v6.5c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2v-6.5c0-1.1.9-2 2-2h.6m9.6-6.5l-7.1 7.1-.8 3.3 3.4-.7 7.1-7.1c.7-.7.7-1.9 0-2.6m-2.6 0c.7-.7 1.9-.7 2.6 0m-2.6 0l-1-1c-.4-.4-1-.4-1.4 0l-2.6 2.6m7.6-1.6l1.3-1.3m-13 15.5s1.4-2.3 3.4 0c0 0 1.4-1.7 4.8-.5'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
