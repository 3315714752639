import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { useLocation } from 'react-router-dom'
import { useDisclosure } from './useDisclosure'

const defaultActivePathCheck = (pathname: string) => pathname === '/home'

export function useLeftRailStateFromStorage(
  isActiveCheck = defaultActivePathCheck
) {
  const { pathname } = useLocation()
  const [value, setValue] = useLocalStorage(
    'left-rail-state',
    isActiveCheck(pathname)
  )

  const { isOpen, ...disclosure } = useDisclosure(value)

  useEffect(() => {
    setValue(isOpen)
  }, [value, setValue, isOpen])

  return { isOpen, ...disclosure }
}
