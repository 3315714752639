import React from 'react'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import { t, loadStrings } from '../defaultStrings'

export function HomeLink({ href = '/' }) {
  loadStrings()
  return (
    <div className='pl-2 h-12 flex items-center justify-center'>
      <a
        href={href}
        aria-label={t('home')}
        className='outline-none focus-visible:shadow-focus-inset rounded-full flex items-center'
      >
        <ToastLogo
          strokeColor='text-white'
          fillColor='bg-brand-50'
          className=''
          testId='toast-logo'
          size='md'
        />
      </a>
    </div>
  )
}
