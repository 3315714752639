import React, { useEffect } from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import FocusTrap from 'focus-trap-react'
import cx from 'classnames'
import { styles, variantType } from './styles'
import { t, loadStrings } from '../defaultStrings'

export function HeaderOverlay({ isOpen = false, ...props }) {
  useDisableScroll(isOpen)
  if (!isOpen) return null
  return (
    <FocusTrap>
      <div
        // elevation: z-20 for global navigation according to our guidelines
        className='fixed inset-0 z-20 h-full overflow-scroll duration-300 delay-150 bg-white ease-out-quart'
        {...props}
      />
    </FocusTrap>
  )
}

export function CloseButton(props: any) {
  loadStrings()
  return (
    <IconButton
      aria-label={t('close-navigation-overlay')}
      icon={<CloseIcon className='text-secondary' accessibility='decorative' />}
      data-testid='close-btn'
      {...props}
    />
  )
}

export function useDisableScroll(isOpen = false) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'visible'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [isOpen])
}

export function MobileDivider({ variant = 'light' }) {
  return (
    <div
      className={cx(
        'h-8 ml-2 mr-1 my-auto',
        styles[variant as variantType].divider
      )}
      style={{ width: 1 }}
    />
  )
}
