import { useEffect, useState } from 'react'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { useLeftRailStateFromStorage } from './useInitialLeftRailState'

export function useNavigationShell() {
  const size = useScreenSize()
  const { isOpen, ...disclosure } = useLeftRailStateFromStorage()
  const [isHovered, setIsHovered] = useState(false)

  useWatchSizeAndOpenStateForBodyMargins({ size, isOpen, isHovered })

  function onMouseOver() {
    setIsHovered(true)
  }

  function onMouseLeave() {
    setIsHovered(false)
  }

  if (size < ScreenSize.MD) {
    return { width: 0, size, disclosure: { isOpen, ...disclosure } }
  }

  const open = isOpen || isHovered

  const width = open ? '13rem' : size < ScreenSize.LG ? 0 : '4rem'
  return {
    width,
    size,
    disclosure: { ...disclosure, isOpen: open },
    onMouseLeave,
    onMouseOver
  }
}

function useWatchSizeAndOpenStateForBodyMargins({
  size = 0,
  isOpen = false,
  isHovered = false
}) {
  useEffect(() => {
    if (size < ScreenSize.MD) {
      document.body.classList.remove('global-navigation-desktop')
      document.body.classList.add('global-navigation-mobile')
    } else {
      document.body.classList.remove('global-navigation-mobile')
      document.body.classList.add('global-navigation-desktop')
    }
  }, [size])

  useEffect(() => {
    if (isHovered) return
    if (size < ScreenSize.MD) {
      document.body.classList.remove('navigation-inactive')
      document.body.classList.remove('navigation-active')
    } else if (isOpen) {
      document.body.classList.add('navigation-active')
      document.body.classList.remove('navigation-inactive')
    } else if (!isOpen && size < ScreenSize.LG) {
      document.body.classList.remove('navigation-inactive')
      document.body.classList.remove('navigation-active')
    } else if (!isOpen && size >= ScreenSize.LG) {
      document.body.classList.remove('navigation-active')
      document.body.classList.add('navigation-inactive')
    } else {
      document.body.classList.remove('navigation-inactive')
      document.body.classList.remove('navigation-active')
    }
  }, [isOpen, size, isHovered])
}
