import React from 'react'
import classnames from 'classnames'
import { TailwindElement } from './TailwindElement'
import { NavigationRailContext } from './NavigationRailContext'
import { t, loadStrings } from '../defaultStrings'
import styles from './NavigationRail.module.css'
import './NavigationRail.global.css'

const cx = classnames.bind(styles)

export function NavigationRail({
  className,
  baseClasses = 'hide-scrollbar pt-0.5',
  label,
  isExpanded = true,
  canExpand = true,
  activePathCheck,
  ...props
}: TailwindElement<HTMLDivElement> & {
  label?: string
  isExpanded?: boolean
  canExpand?: boolean
  activePathCheck?: Function
}) {
  loadStrings()
  if (!label) {
    label = t('left-rail-navigation')
  }
  return (
    <NavigationRailContext.Provider
      value={{ isExpanded, canExpand, activePathCheck }}
    >
      <nav
        aria-label={label}
        className={cx(baseClasses, className)}
        {...props}
      />
    </NavigationRailContext.Provider>
  )
}

export function NavigationGroup({
  baseClasses = 'first:pt-3 py-2.5 border-b mx-2 list-none last:border-transparent last:pb-20',
  className,
  ...props
}: TailwindElement<HTMLUListElement>) {
  return <ul className={cx(className, baseClasses)} {...props} />
}

export function NavigationRailLayout({
  className,
  baseClasses = 'md:mt-16 md:h-screen md:fixed xl:block bg-white left-0 top-0 transition-all duration-300 ease-out-quart',
  ...props
}: TailwindElement<HTMLDivElement>) {
  return <div className={cx(baseClasses, className)} {...props} />
}

export const NavigationRailContainer = React.forwardRef<
  HTMLDivElement,
  TailwindElement<HTMLDivElement>
>(
  (
    {
      className,
      baseClasses = 'h-full overflow-auto transition-all duration-300 ease-out-quart border-r group',
      ...props
    }: TailwindElement<HTMLDivElement>,
    ref
  ) => {
    return <div ref={ref} className={cx(baseClasses, className)} {...props} />
  }
)
