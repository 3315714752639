import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const LaptopIcon = React.forwardRef(function LaptopIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M19.95 14.5h-16m16 0V5.3c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2v9.2m16 0l2 4.8c.3.7-.2 1.4-.9 1.4H2.95c-.7 0-1.2-.8-.9-1.4l1.9-4.8m6.2 3.6h3.5'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
