import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

type HeapPropertiesObject = Record<
  string,
  number | boolean | null | undefined | string | object
>

// https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions
interface Heap {
  track: (event: string, properties?: HeapPropertiesObject) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: HeapPropertiesObject) => void
  addEventProperties: (properties: HeapPropertiesObject) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  config: any
}

declare global {
  interface Window {
    heap?: Heap
  }
}

const trimStringTo255Chars = (s: string) => s.slice(0, 255)
const trimValueTo1024Chars = (val: any) => String(val).slice(0, 1024)
const sanitizeEventProps = (eventProps: HeapPropertiesObject = {}) =>
  Object.entries(eventProps).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [trimValueTo1024Chars(key)]:
        typeof val === 'number'
          ? val
          : typeof val === 'boolean' || !val
          ? String(val)
          : trimValueTo1024Chars(
              typeof val === 'string' ? val : JSON.stringify(val)
            )
    }),
    {}
  )

/**
 * Specify a set of global key-value pairs to get attached to all of a user's
 * subsequent events.
 *
 * @param eventProperties Map containing key-value pairs to be associated
 * with every subsequent event.
 */
export const addEventProperties = (properties: HeapPropertiesObject) => {
  if (window.heap) {
    window.heap.addEventProperties(sanitizeEventProps(properties))
  }
}

/**
 * Attach custom properties to user profiles.
 *
 * @param userProperties Map containing key-value pairs to be associated with
 * a user.
 */
export const addUserProperties = (properties: HeapPropertiesObject) => {
  if (window.heap) {
    window.heap.addUserProperties(sanitizeEventProps(properties))
  }
}

/**
 * Attach a unique identity to a user.
 *
 * @param identity A string that uniquely identifies a user, such as an
 * email, handle, or username. This means no two users in one environment may
 * share the same identity. Must be fewer than 255 characters.
 */
export const identify = (identity: string) => {
  const currentEnvironment = getCurrentEnvironment()

  if (currentEnvironment === ToastEnvironment.PREPROD) {
    console.warn(
      "The use of heap.identify is strongly discouraged, the heap session is likely already set by your SPA's webserver. Only use this if you are sure that you need to."
    )
  }

  const invalidValues = [null, undefined, true, false, 'user']

  if (window.heap && !invalidValues.includes(identity)) {
    window.heap.identify(trimStringTo255Chars(identity))
  }
}

// https://developers.heap.io/docs/web#precise-data-redaction-via-heap-redact
export const redactTextContent = () => ({ 'data-heap-redact-text': 'true' })

export const redactAttributes = (attributes: string[]) => ({
  'data-heap-redact-attributes': attributes.join(', ')
})

/**
 * Send additional events to Heap.
 *
 * Note: track events cannot be named click, change, submit as it clashes with internal variables.
 *
 * @param eventName Name of the custom interaction. Trimmed to 255 characters.
 * @param data Map containing key-value pairs to be associated with an event.
 */
export const track = (event: string, properties?: HeapPropertiesObject) => {
  const invalidEvents = ['click', 'change', 'submit']

  if (window.heap && !invalidEvents.includes(event)) {
    window.heap.track(trimStringTo255Chars(event), properties)
  }
}

export const useHeap = () => ({
  addEventProperties,
  addUserProperties,
  identify,
  redactAttributes,
  redactTextContent,
  track
})
