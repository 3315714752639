import * as React from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { useNavigationRailState } from './NavigationRailContext'
import {
  NavigationIcon,
  NavigationItem,
  NavigationItemText,
  NavigationLink,
  NavLinkDensity
} from './NavigationItem'

export type LinkItemProps = {
  icon: React.FC<React.PropsWithChildren<any>>
  isActive?: boolean
  isHover?: boolean
  to?: string
  testId?: string
  href?: string
  density?: NavLinkDensity
  openNewTab?: boolean
}

export const LinkItem: React.FC<React.PropsWithChildren<LinkItemProps>> = ({
  icon,
  isActive: forcedActive = false,
  isHover = false,
  testId,
  to,
  href,
  density = 'default',
  openNewTab = false,
  ...props
}) => {
  const { activePathCheck } = useNavigationRailState()
  const location = useLocation()
  const allProps = {
    location,
    to,
    href,
    icon,
    forcedActive,
    ...props
  }
  const isActive = forcedActive || isLinkActive(activePathCheck, allProps)

  const extraLinkProps = React.useMemo(() => {
    if (openNewTab)
      return {
        target: '_blank',
        // noopener is no longer needed with target='_blank'
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noopener
        rel: 'noreferrer'
      }
    return {}
  }, [openNewTab])

  return (
    <NavigationItem isActive={isActive} isHover={isHover} density={density}>
      <NavigationLink
        as={href ? 'a' : Link}
        testId={testId}
        density={density}
        {...(href ? { href, ...extraLinkProps } : { to })}
      >
        {/* (not a buffet icon) */}
        {/* eslint-disable-next-line @toasttab/buffet/icon-accessibility */}
        <NavigationIcon icon={icon} />
        <NavigationItemText {...props} />
      </NavigationLink>
    </NavigationItem>
  )
}

interface ActiveLinkCheckProps {
  location: { pathname: string }
  to?: string
  href?: string
  icon: React.FC<React.PropsWithChildren<unknown>>
  forcedActive: boolean
}

function isLinkActive(
  activePathCheck: Function | undefined,
  allProps: ActiveLinkCheckProps
) {
  if (activePathCheck) return activePathCheck(allProps)
  return defaultPathCheck(allProps)
}

function defaultPathCheck({ location, to }: ActiveLinkCheckProps) {
  const deepMatch = matchPath(location.pathname, `${to}/*`)
  const pageMatch = matchPath(location.pathname, String(to))
  if (to === '/') {
    return location.pathname === to
  } else {
    return deepMatch || pageMatch
  }
}
