import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ToastAccountIcon = React.forwardRef(function ToastAccountIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M18.05 19.55c-.4-3-3-5.3-6.1-5.3-3.1 0-5.6 2.3-6.1 5.3m9.6-9.3a3.4 3.4 0 11-6.8 0 3.4 3.4 0 016.8 0zm6.6 1.8c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
