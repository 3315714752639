import { useState } from 'react'

export function useDisclosure(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState)

  function onOpen() {
    setIsOpen(true)
  }

  function onClose() {
    setIsOpen(false)
  }

  function onToggle() {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle
  }
}
