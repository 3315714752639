import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import {
  useCheckDepotFeatureFlag,
  useDepotBanquetProps
} from '@toasttab/depot-banquet-props'

const SWITCHER_AVAILABLE_ENVS: { [key: string]: string[] } = {
  dev: ['TEST (MAIN)', 'TEST (SECONDARY)'],
  preprod: ['PREPROD (MAIN)', 'PREPROD (SECONDARY)'],
  prod: ['PRODUCTION', 'SANDBOX']
}

export function getMainForEnvironment(env: string): string {
  return SWITCHER_AVAILABLE_ENVS[env][0]
}
export function getSecondaryForEnvironment(env: string): string {
  return SWITCHER_AVAILABLE_ENVS[env][1]
}

type EnvironmentContextValue = {
  isSwitcherEnabled: boolean
  isSelectionDisabled: boolean
  disableSelection: (arg0: boolean) => void
  environmentSelection: string | undefined
  switchEnvironment: (arg0: string) => void
}

export const EnvironmentContext = createContext<EnvironmentContextValue>({
  isSwitcherEnabled: false,
  isSelectionDisabled: false,
  disableSelection: () => null,
  environmentSelection: undefined,
  switchEnvironment: () => null
})

export const EnvironmentContextProvider = ({ children }: any) => {
  const hasCrossEnvGuid = useDepotBanquetProps().partner.crossEnvGuid !== null
  const envFF = useCheckDepotFeatureFlag('partners-env-switcher')
  const { userEnvironments } = useDepotBanquetProps()
  const userHasAccessBothEnvironments =
    userEnvironments?.includes('main') && userEnvironments.includes('secondary')

  const isSwitcherEnabled =
    (envFF && hasCrossEnvGuid && userHasAccessBothEnvironments) ?? false

  // Toast Environment
  const currentEnvironment = getCurrentEnvironment() as string

  //Active environment
  const defaultEnvironment = getMainForEnvironment(currentEnvironment)
  const [environmentSelection, setEnvironmentSelection] =
    useState(defaultEnvironment)

  const switchEnvironment = useCallback(
    (selection: string) => {
      isSwitcherEnabled && setEnvironmentSelection(selection)
    },
    [isSwitcherEnabled]
  )

  const [isSelectionDisabled, disableSelection] = useState(false)
  const providerValue = useMemo(
    () => ({
      isSwitcherEnabled,
      isSelectionDisabled,
      disableSelection,
      environmentSelection,
      switchEnvironment
    }),
    [
      environmentSelection,
      isSelectionDisabled,
      isSwitcherEnabled,
      switchEnvironment
    ]
  )

  return (
    <EnvironmentContext.Provider value={providerValue}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export function useEnvironmentContext() {
  return useContext(EnvironmentContext)
}
