import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ViewCardIcon = React.forwardRef(function ViewCardIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              clipRule='evenodd'
              d='M8.9 10.05H4.1c-.6 0-1.1-.5-1.1-1.1v-4.8c0-.6.5-1.1 1.1-1.1h4.8c.6 0 1.1.5 1.1 1.1v4.8c0 .6-.4 1.1-1.1 1.1zM19.9 10.05h-4.8c-.6 0-1.1-.5-1.1-1.1v-4.8c0-.6.5-1.1 1.1-1.1h4.8c.6 0 1.1.5 1.1 1.1v4.8c0 .6-.5 1.1-1.1 1.1zM8.9 20.95H4.1c-.6 0-1.1-.5-1.1-1.1v-4.8c0-.6.5-1.1 1.1-1.1h4.8c.6 0 1.1.5 1.1 1.1v4.8c0 .7-.4 1.1-1.1 1.1zM19.9 20.95h-4.8c-.6 0-1.1-.5-1.1-1.1v-4.8c0-.6.5-1.1 1.1-1.1h4.8c.6 0 1.1.5 1.1 1.1v4.8c0 .7-.5 1.1-1.1 1.1z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
