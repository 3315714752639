import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ToastCentralIcon = React.forwardRef(function ToastCentralIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M11.998 16.594v-8.47c0-1.974-1.17-3.573-3.052-3.573H3v12.043h6.307m5.557.007a2.846 2.846 0 00-2.848 2.848 2.848 2.848 0 00-2.848-2.848m-3.401-4.206h3.037M5.767 8.65h3.037m3.194 9.907V7.949a3.323 3.323 0 013.245-3.398H21v12.043h-6.132m3.256-4.2h-3.01m3.002-3.744h-3.009'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
