import * as React from 'react'

export const NavigationRailContext = React.createContext<{
  canExpand?: boolean
  isExpanded?: boolean
  activePathCheck?: Function
}>({
  canExpand: false,
  isExpanded: true,
  activePathCheck: undefined
})

export function useNavigationRailState() {
  return React.useContext(NavigationRailContext)
}
