import * as React from 'react'

type AsProp<C extends React.ElementType> = {
  as?: C
}

type HeaderControlProps<C extends React.ElementType> = React.PropsWithChildren<
  AsProp<C>
> &
  React.ComponentPropsWithoutRef<C> & {
    ref?: React.ComponentPropsWithRef<C>['ref']
  }
type HeaderControlComponent = <C extends React.ElementType = 'button'>(
  props: HeaderControlProps<C>
) => React.ReactElement | null

export const HeaderControl: HeaderControlComponent = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    { as, children, ...props }: HeaderControlProps<C>,
    ref?: React.ComponentPropsWithRef<C>['ref']
  ) => {
    const Component = as || 'button'

    return (
      <Component
        ref={ref}
        className='flex items-center outline-none group p-1 type-subhead h-full md:p-0 text-primary-75 hover:text-primary-100 focus-visible:text-primary-100 no-underline'
        {...props}
      >
        <div className='p-2 type-subhead flex items-center border-white outline-none rounded-button group-hover:bg-darken-4 group-focus-visible:shadow-focus'>
          {children}
        </div>
      </Component>
    )
  }
)
